.skills {
    width: 100vw;
    height: fit-content;
    background-color: white;
    text-align: center;
    padding: 60px;
}

.whatIKnow {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.skillContainer {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 60px;
    width: 100%;
}

.leftSkill {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    padding-left: 20px;
}

.rightSkill1 {
    display: flex;
    flex-direction: column;
    grid-template-columns: repeat(2, 1fr);
    padding: 20px;
    grid-gap: 20px;
}

.right3 {
    display: flex;
    align-items: center;
    justify-content: center;
}

.programmingSkills {
    display: flex;
    flex-direction: column;
    padding: 10px;
    margin: 10px;
    align-items: center;
    justify-content: center;
    color: black;
}

.skillHeading {
    font-weight: 600;
    color: black;
    font-size: 28px;
    margin-bottom: 30px
}

.programmingSkills img {
    border-radius: 50%;
    width: 50px;
    height: 50px;
    object-fit: cover;
    animation: animateImg .8s infinite ease-in;
    /* margin-right: 20px; */
}

.programmingSkills p {
    font-size: 20px;
    font-weight: 700;
}

@keyframes animateImg {
    0% {
        transform: scaleX(1);
    }
    25% {
        transform: scaleX(1.1);
    }
    100% {
        transform: scaleX(1);
    }
}

@media (max-width:500px) {
    .skills {
        padding: 10px;
    }
    .whatIKnow {
        margin-top: 57px;
    }
    .skillContainer {
        display: grid;
        grid-template-columns: 1fr;
        place-items: center;
        grid-gap: 10px;
    }
    .leftSkill p {
        display: block;
        width: 99%;
        padding: 3px;
        margin: 5px auto;
        max-width: 500px;
    }
    .leftSkill {
        padding: 0;
    }
    .rightSkill1 {
        margin-top: -25px;
        padding: 10px;
    }
    .right3 {
        padding: 3px;
    }
    .programmingSkills img {
        width: 30px;
        height: 30px;
    }
    .programmingSkills p {
        font-size: 16px;
    }
    .skillHeading {
        font-size: 22px;
    }
}