@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700;900&display=swap);
::-webkit-scrollbar {
    display: none;
}

* {
    margin: 0;
    box-sizing: border-box;
    font-family: 'Roboto', sans-serif;
    text-decoration: none;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.about {
    width: 100vw;
    height: 80vh;
    padding-top: 20px;
    background: linear-gradient(35deg, rgb(163, 2, 147) 20%, rgb(163, 2, 247) 40%, white 40% 100%);
}

.dhruvCool {
    display: flex;
}

.aboutMe {
    margin-top: 50px;
    font-size: 30px;
    font-weight: 800;
    text-align: center;
}

.aboutMe p:nth-child(2) {
    font-size: small;
    color: red;
    font-weight: 900;
    margin-bottom: 20px;
}

.aboutMeElemContainer {
    display: flex;
    width: 100vw;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.leftContainer {
    margin-left: 240px;
    width: 250px;
    height: 300px;
    padding: 4px;
    overflow: hidden;
    background-color: darkgray;
    border-radius: 5px;
}

.leftContainer img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform .3s ease;
}

.leftContainer img:hover {
    transform: scale(1.04);
}

.right1 {
    margin-left: 100px;
    display: flex;
    margin-top: 10px;
    flex-direction: column;
    justify-content: start;
    padding-left: 20px;
}

.body {
    margin-top: 15px;
    color: darkgray;
    margin-bottom: 20px;
    font-weight: 700;
    font-size: 18px;
}

.intro {
    font-weight: 600;
    line-height: 35px;
    max-width: 550px;
    font-style: italic;
}

.button {
    margin-top: 20px;
    padding: 12px 12px;
    border-radius: 5px;
    width: 50%;
    margin: 20px auto;
    border: none;
    outline: none;
    cursor: pointer;
    font-size: 18px;
    color: white;
    background: linear-gradient(35deg, rgb(163, 2, 147) 20%, rgb(163, 2, 247) 40%);
    font-weight: 500;
    transition: all .3 ease-in-out;
}

.button:hover {
    background: linear-gradient(35deg, rgb(163, 2, 247) 20%, rgb(163, 2, 147) 40%);
}

.type {
    font-size: 22px;
}

@media (max-width:600px) {
    .about {
        background: linear-gradient(180deg, rgb(163, 2, 147) 20%, rgb(163, 2, 247) 40%, white 40% 100%);
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;
    }
    .aboutMe {
        margin-top: 50px;
        color: white;
    }
    .dhruvCool {
        flex-direction: column;
    }
    .leftContainer {
        width: 60%;
        margin: 10px auto;
        height: 200px;
    }
    .right1 {
        width: 100%;
        flex-direction: column;
        align-items: center;
        margin-left: 0px;
        padding: 0px;
        margin: 20px auto;
    }
    .type {
        font-size: 10px;
    }
    .typical {
        font-size: 20px;
    }
    .intro {
        display: block;
        width: 90%;
        margin: 10px auto;
        line-height: 18px;
    }
    .body {
        max-width: 80%;
    }
    .button {
        margin: 5px auto;
        padding: 5px;
        width: 90%;
    }
}
.Header {
    position: fixed;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: white;
    padding: 14px;
    z-index: 50;
    background: linear-gradient(to right, rgb(91, 0, 176), rgb(154, 2, 162), rgb(185, 3, 112), rgb(254, 8, 59));
}

.Header1 {
    background: black;
}

.left {
    font-style: italic;
    font-size: 30px;
    font-weight: bolder;
    letter-spacing: 1.1px;
}

.left span {
    font-weight: 2000;
    color: red;
}

.right {
    display: flex;
    font-style: italic;
    align-items: center;
    margin-left: 20px;
    justify-content: space-between;
}

.right p {
    font-size: 20px;
    font-weight: 700;
    margin-left: 25px;
    transition: all .3 ease-in-out;
    cursor: pointer;
}

.link {
    font-size: 20px;
    font-weight: 700;
    margin-left: 25px;
    transition: all .3 ease-in-out;
    cursor: pointer;
    padding: 5px;
    text-align: center;
}

.link1 {
    font-size: 20px;
    font-weight: 700;
    margin-left: 25px;
    transition: all .3 ease-in-out;
    cursor: pointer;
    padding: 5px;
    text-align: center;
    color: white;
}

.link1:hover {
    transform: scaleX(1.1);
}

.link:hover {
    font-weight: 1000;
    color: rgba(255, 0, 0, .9);
}

@media (max-width: 600px) {
    .right {
        display: none;
    }
}
.homeContainer {
    height: 100vh;
    width: 100vw;
    background: url(/static/media/website-parallax-background-C.571ce743.jpg);
}

.homeElement {
    width: 50%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    z-index: 2;
    flex-direction: column;
}

.hello {
    margin-bottom: 15px;
    font-weight: 700;
    font-size: 25px;
    margin-right: -7px;
}

.name {
    margin-bottom: 15px;
    font-weight: bolder;
    font-size: 55px;
}

.typical {
    color: rgb(254, 8, 59);
}

.type {
    font-size: 30px;
    font-weight: bolder;
}

.hireMe {
    margin-top: 50px;
    padding: 12px 12px;
    border-radius: 5px;
    width: 30%;
    border: none;
    outline: none;
    cursor: pointer;
    font-size: 18px;
    color: white;
    background: linear-gradient(35deg, rgb(163, 2, 147) 20%, rgb(163, 2, 247) 40%);
    font-weight: 500;
    transition: all .3 ease-in-out;
}

.hireMe:hover {
    background: linear-gradient(35deg, rgb(163, 2, 247) 20%, rgb(163, 2, 147) 40%);
}

@media (max-width:500px) {
    .homeContainer {
        height: 80vh;
    }
    .homeElement {
        justify-content: left;
        align-items: baseline;
        padding-top: 150px;
        padding-left: 15px;
        width: 100%;
        text-align: center;
    }
    .hello {
        font-size: 20px;
    }
    .name {
        font-size: 40px;
    }
    .type {
        font-size: 22px;
    }
}
    .buttonLink {
        position: fixed;
        bottom: 30px;
        z-index: 50;
        right: 30px;
        padding: 1px 4px;
        background: linear-gradient(to right, rgb(91, 0, 176), rgb(154, 2, 162), rgb(185, 3, 112), rgb(254, 8, 59));
        cursor: pointer;
        animation: animate 1s infinite ease-in;
    }
    
    .buttonLink:hover {
        background: linear-gradient(to left, rgb(91, 0, 176), rgb(154, 2, 162), rgb(185, 3, 112), rgb(254, 8, 59));
    }
    
    @keyframes animate {
        0% {
            bottom: 30px;
        }
        100% {
            bottom: 35px
        }
    }
.serviceContainer {
    width: 100vw;
    height: 90vh;
    padding: 20px;
    color: white;
    background-color: black;
}

.service1 {
    margin-top: 55px;
    width: 100%;
    text-align: center;
}

.provide {
    font-weight: 600;
    color: rgba(205, 0, 0, 1);
}

.service {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    margin-top: 80px;
    grid-gap: 20px;
}

.service2 {
    background-color: rgb(32, 32, 32);
    display: flex;
    padding: 10px;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    transition: all .3s ease;
}

.service2:hover {
    background-color: #0213A0;
}

.air {
    font-size: 70px !important;
}

@media (max-width:500px) {
    .serviceContainer {
        padding: 10px;
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;
    }
    .service1 {
        padding: 10px;
    }
    .service {
        margin-top: 10px;
        grid-template-columns: 1fr;
    }
    .air {
        font-size: 40px !important;
    }
}
.skills {
    width: 100vw;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    background-color: white;
    text-align: center;
    padding: 60px;
}

.whatIKnow {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.skillContainer {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 60px;
    width: 100%;
}

.leftSkill {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    padding-left: 20px;
}

.rightSkill1 {
    display: flex;
    flex-direction: column;
    grid-template-columns: repeat(2, 1fr);
    padding: 20px;
    grid-gap: 20px;
}

.right3 {
    display: flex;
    align-items: center;
    justify-content: center;
}

.programmingSkills {
    display: flex;
    flex-direction: column;
    padding: 10px;
    margin: 10px;
    align-items: center;
    justify-content: center;
    color: black;
}

.skillHeading {
    font-weight: 600;
    color: black;
    font-size: 28px;
    margin-bottom: 30px
}

.programmingSkills img {
    border-radius: 50%;
    width: 50px;
    height: 50px;
    object-fit: cover;
    animation: animateImg .8s infinite ease-in;
    /* margin-right: 20px; */
}

.programmingSkills p {
    font-size: 20px;
    font-weight: 700;
}

@keyframes animateImg {
    0% {
        transform: scaleX(1);
    }
    25% {
        transform: scaleX(1.1);
    }
    100% {
        transform: scaleX(1);
    }
}

@media (max-width:500px) {
    .skills {
        padding: 10px;
    }
    .whatIKnow {
        margin-top: 57px;
    }
    .skillContainer {
        display: grid;
        grid-template-columns: 1fr;
        place-items: center;
        grid-gap: 10px;
    }
    .leftSkill p {
        display: block;
        width: 99%;
        padding: 3px;
        margin: 5px auto;
        max-width: 500px;
    }
    .leftSkill {
        padding: 0;
    }
    .rightSkill1 {
        margin-top: -25px;
        padding: 10px;
    }
    .right3 {
        padding: 3px;
    }
    .programmingSkills img {
        width: 30px;
        height: 30px;
    }
    .programmingSkills p {
        font-size: 16px;
    }
    .skillHeading {
        font-size: 22px;
    }
}
.projectContainer12 {
    width: 100vw;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    background: black;
    color: white;
    padding-bottom: 20px;
}

.projPara {
    margin-top: 58px;
}

.project {
    margin-top: 30px;
    display: flex;
    align-items: center;
    padding: 10px;
    width: 100%;
}

.project1 {
    background-color: rgb(32, 32, 32);
    margin: 10px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 20px;
    color: white;
    transition: all .3s ease-in;
}

.project1:hover {
    background-color: red;
    transform: translateY(-10px);
}

.project1s {
    width: 100%;
    display: flex;
}

.project1 {
    margin: 10px;
}

.project1 img {
    width: 200px;
    height: 200px;
    border-radius: 50%;
    padding: 5px;
    background-color: lightgray;
}

.project h2 {
    margin-top: 20px;
    font-size: 20px;
}

.project1 p {
    font-size: 16px;
    font-weight: 500;
    margin-top: 20px;
    max-width: 600px;
}

.project2 {
    border: 2px solid red;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 20px;
}

@media (max-width:500px) {
    .project {
        align-items: center;
        padding-left: -6px;
        flex-direction: column;
    }
    .projPara {
        margin-top: 58px;
    }
    .project1s {
        width: 100%;
        margin: 4px;
        align-items: center;
        flex-direction: column;
        padding: 10px;
    }
    .project1 img {
        width: 130px;
        height: 130px;
    }
}
.contactContainer {
    width: 100vw;
    height: 70vh;
    padding: 20px;
}

.contact {
    margin-top: 40px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 20px;
    width: 100%;
    padding: 20px;
}

.leftContact {
    padding-left: 40px;
    display: flex;
    flex-direction: column;
    justify-content: start;
}

.leftContact p {
    max-width: 600px;
}

.contactIcon {
    width: 100%;
    display: flex;
    flex-direction: column;
    font-size: 20px;
    padding-left: 20px;
    margin-top: 30px;
}

.iconContact {
    font-size: 80px !important
}

.contactElem {
    display: flex;
    align-items: center;
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 15px;
}

.contactElem p {
    margin-left: 7px;
}

.contact-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
}

.contact-form input,
textarea {
    width: 60%;
    padding: 5px 8px;
    border-radius: 5px;
    margin: 5px auto;
    outline: none;
    border: 2px solid lightgray;
}

.contact-form input:focus {
    box-shadow: 0 0px 6px rgb(93, 0, 176);
}

.textarea:hover {
    border-color: rgb(93, 0, 176);
}

.contact-form input[type='submit'] {
    margin-top: 20px;
    padding: 12px 12px;
    border-radius: 5px;
    width: 50%;
    margin: 20px auto;
    border: none;
    outline: none;
    cursor: pointer;
    font-size: 18px;
    color: white;
    background: linear-gradient(35deg, rgb(163, 2, 147) 20%, rgb(163, 2, 247) 40%);
    font-weight: 500;
    transition: all .3 ease-in-out;
}

.contact-form input[type='submit']:hover {
    background: linear-gradient(35deg, rgb(163, 2, 247) 20%, rgb(163, 2, 147) 40%);
}

@media (max-width:600px) {
    .contact {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .leftContact {
        padding-left: 3px;
    }
    .leftContact p {
        display: block;
        width: 98%;
        margin: 5px auto;
    }
    .contactElem {
        font-size: 15px;
    }
    .contact-form input,
    textarea {
        width: 90%;
    }
    .contactElem p {
        max-width: 100px;
        margin-left: 14px;
    }
    .contact-form {
        padding: 4px;
    }
    .contact-form input[type='submit'] {
        padding: 3px 10px;
        width: 80%;
    }
    .touch {
        margin-top: 48px;
    }
}
.line {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    position: fixed;
    top: 15px;
    right: 15px;
    color: white;
    z-index: 7888;
    display: none;
}

@media (max-width:600px) {
    .line {
        display: block;
    }
}
.sideDrawer {
    z-index: 6000;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    flex-direction: column;
    display: none;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, .9);
    position: fixed;
}

.sideLink {
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    color: aliceblue;
    margin-bottom: 30px;
    font-size: 20px;
    font-weight: 600;
    transition: color .3s ease-in;
}

.sideLink:hover {
    color: red;
}

@media (max-width:600px) {
    .sideDrawer {
        display: flex;
    }
}
