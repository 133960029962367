    .buttonLink {
        position: fixed;
        bottom: 30px;
        z-index: 50;
        right: 30px;
        padding: 1px 4px;
        background: linear-gradient(to right, rgb(91, 0, 176), rgb(154, 2, 162), rgb(185, 3, 112), rgb(254, 8, 59));
        cursor: pointer;
        animation: animate 1s infinite ease-in;
    }
    
    .buttonLink:hover {
        background: linear-gradient(to left, rgb(91, 0, 176), rgb(154, 2, 162), rgb(185, 3, 112), rgb(254, 8, 59));
    }
    
    @keyframes animate {
        0% {
            bottom: 30px;
        }
        100% {
            bottom: 35px
        }
    }