.sideDrawer {
    z-index: 6000;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    flex-direction: column;
    display: none;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, .9);
    position: fixed;
}

.sideLink {
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    color: aliceblue;
    margin-bottom: 30px;
    font-size: 20px;
    font-weight: 600;
    transition: color .3s ease-in;
}

.sideLink:hover {
    color: red;
}

@media (max-width:600px) {
    .sideDrawer {
        display: flex;
    }
}