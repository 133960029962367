.serviceContainer {
    width: 100vw;
    height: 90vh;
    padding: 20px;
    color: white;
    background-color: black;
}

.service1 {
    margin-top: 55px;
    width: 100%;
    text-align: center;
}

.provide {
    font-weight: 600;
    color: rgba(205, 0, 0, 1);
}

.service {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    margin-top: 80px;
    grid-gap: 20px;
}

.service2 {
    background-color: rgb(32, 32, 32);
    display: flex;
    padding: 10px;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    transition: all .3s ease;
}

.service2:hover {
    background-color: #0213A0;
}

.air {
    font-size: 70px !important;
}

@media (max-width:500px) {
    .serviceContainer {
        padding: 10px;
        height: fit-content;
    }
    .service1 {
        padding: 10px;
    }
    .service {
        margin-top: 10px;
        grid-template-columns: 1fr;
    }
    .air {
        font-size: 40px !important;
    }
}