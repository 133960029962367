.contactContainer {
    width: 100vw;
    height: 70vh;
    padding: 20px;
}

.contact {
    margin-top: 40px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 20px;
    width: 100%;
    padding: 20px;
}

.leftContact {
    padding-left: 40px;
    display: flex;
    flex-direction: column;
    justify-content: start;
}

.leftContact p {
    max-width: 600px;
}

.contactIcon {
    width: 100%;
    display: flex;
    flex-direction: column;
    font-size: 20px;
    padding-left: 20px;
    margin-top: 30px;
}

.iconContact {
    font-size: 80px !important
}

.contactElem {
    display: flex;
    align-items: center;
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 15px;
}

.contactElem p {
    margin-left: 7px;
}

.contact-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
}

.contact-form input,
textarea {
    width: 60%;
    padding: 5px 8px;
    border-radius: 5px;
    margin: 5px auto;
    outline: none;
    border: 2px solid lightgray;
}

.contact-form input:focus {
    box-shadow: 0 0px 6px rgb(93, 0, 176);
}

.textarea:hover {
    border-color: rgb(93, 0, 176);
}

.contact-form input[type='submit'] {
    margin-top: 20px;
    padding: 12px 12px;
    border-radius: 5px;
    width: 50%;
    margin: 20px auto;
    border: none;
    outline: none;
    cursor: pointer;
    font-size: 18px;
    color: white;
    background: linear-gradient(35deg, rgb(163, 2, 147) 20%, rgb(163, 2, 247) 40%);
    font-weight: 500;
    transition: all .3 ease-in-out;
}

.contact-form input[type='submit']:hover {
    background: linear-gradient(35deg, rgb(163, 2, 247) 20%, rgb(163, 2, 147) 40%);
}

@media (max-width:600px) {
    .contact {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .leftContact {
        padding-left: 3px;
    }
    .leftContact p {
        display: block;
        width: 98%;
        margin: 5px auto;
    }
    .contactElem {
        font-size: 15px;
    }
    .contact-form input,
    textarea {
        width: 90%;
    }
    .contactElem p {
        max-width: 100px;
        margin-left: 14px;
    }
    .contact-form {
        padding: 4px;
    }
    .contact-form input[type='submit'] {
        padding: 3px 10px;
        width: 80%;
    }
    .touch {
        margin-top: 48px;
    }
}