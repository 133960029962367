.about {
    width: 100vw;
    height: 80vh;
    padding-top: 20px;
    background: linear-gradient(35deg, rgb(163, 2, 147) 20%, rgb(163, 2, 247) 40%, white 40% 100%);
}

.dhruvCool {
    display: flex;
}

.aboutMe {
    margin-top: 50px;
    font-size: 30px;
    font-weight: 800;
    text-align: center;
}

.aboutMe p:nth-child(2) {
    font-size: small;
    color: red;
    font-weight: 900;
    margin-bottom: 20px;
}

.aboutMeElemContainer {
    display: flex;
    width: 100vw;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.leftContainer {
    margin-left: 240px;
    width: 250px;
    height: 300px;
    padding: 4px;
    overflow: hidden;
    background-color: darkgray;
    border-radius: 5px;
}

.leftContainer img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform .3s ease;
}

.leftContainer img:hover {
    transform: scale(1.04);
}

.right1 {
    margin-left: 100px;
    display: flex;
    margin-top: 10px;
    flex-direction: column;
    justify-content: start;
    padding-left: 20px;
}

.body {
    margin-top: 15px;
    color: darkgray;
    margin-bottom: 20px;
    font-weight: 700;
    font-size: 18px;
}

.intro {
    font-weight: 600;
    line-height: 35px;
    max-width: 550px;
    font-style: italic;
}

.button {
    margin-top: 20px;
    padding: 12px 12px;
    border-radius: 5px;
    width: 50%;
    margin: 20px auto;
    border: none;
    outline: none;
    cursor: pointer;
    font-size: 18px;
    color: white;
    background: linear-gradient(35deg, rgb(163, 2, 147) 20%, rgb(163, 2, 247) 40%);
    font-weight: 500;
    transition: all .3 ease-in-out;
}

.button:hover {
    background: linear-gradient(35deg, rgb(163, 2, 247) 20%, rgb(163, 2, 147) 40%);
}

.type {
    font-size: 22px;
}

@media (max-width:600px) {
    .about {
        background: linear-gradient(180deg, rgb(163, 2, 147) 20%, rgb(163, 2, 247) 40%, white 40% 100%);
        height: fit-content;
    }
    .aboutMe {
        margin-top: 50px;
        color: white;
    }
    .dhruvCool {
        flex-direction: column;
    }
    .leftContainer {
        width: 60%;
        margin: 10px auto;
        height: 200px;
    }
    .right1 {
        width: 100%;
        flex-direction: column;
        align-items: center;
        margin-left: 0px;
        padding: 0px;
        margin: 20px auto;
    }
    .type {
        font-size: 10px;
    }
    .typical {
        font-size: 20px;
    }
    .intro {
        display: block;
        width: 90%;
        margin: 10px auto;
        line-height: 18px;
    }
    .body {
        max-width: 80%;
    }
    .button {
        margin: 5px auto;
        padding: 5px;
        width: 90%;
    }
}