.homeContainer {
    height: 100vh;
    width: 100vw;
    background: url("../images/website-parallax-background-C.jpg");
}

.homeElement {
    width: 50%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    z-index: 2;
    flex-direction: column;
}

.hello {
    margin-bottom: 15px;
    font-weight: 700;
    font-size: 25px;
    margin-right: -7px;
}

.name {
    margin-bottom: 15px;
    font-weight: bolder;
    font-size: 55px;
}

.typical {
    color: rgb(254, 8, 59);
}

.type {
    font-size: 30px;
    font-weight: bolder;
}

.hireMe {
    margin-top: 50px;
    padding: 12px 12px;
    border-radius: 5px;
    width: 30%;
    border: none;
    outline: none;
    cursor: pointer;
    font-size: 18px;
    color: white;
    background: linear-gradient(35deg, rgb(163, 2, 147) 20%, rgb(163, 2, 247) 40%);
    font-weight: 500;
    transition: all .3 ease-in-out;
}

.hireMe:hover {
    background: linear-gradient(35deg, rgb(163, 2, 247) 20%, rgb(163, 2, 147) 40%);
}

@media (max-width:500px) {
    .homeContainer {
        height: 80vh;
    }
    .homeElement {
        justify-content: left;
        align-items: baseline;
        padding-top: 150px;
        padding-left: 15px;
        width: 100%;
        text-align: center;
    }
    .hello {
        font-size: 20px;
    }
    .name {
        font-size: 40px;
    }
    .type {
        font-size: 22px;
    }
}