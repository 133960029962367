.line {
    width: fit-content;
    height: fit-content;
    position: fixed;
    top: 15px;
    right: 15px;
    color: white;
    z-index: 7888;
    display: none;
}

@media (max-width:600px) {
    .line {
        display: block;
    }
}