.projectContainer12 {
    width: 100vw;
    height: fit-content;
    background: black;
    color: white;
    padding-bottom: 20px;
}

.projPara {
    margin-top: 58px;
}

.project {
    margin-top: 30px;
    display: flex;
    align-items: center;
    padding: 10px;
    width: 100%;
}

.project1 {
    background-color: rgb(32, 32, 32);
    margin: 10px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 20px;
    color: white;
    transition: all .3s ease-in;
}

.project1:hover {
    background-color: red;
    transform: translateY(-10px);
}

.project1s {
    width: 100%;
    display: flex;
}

.project1 {
    margin: 10px;
}

.project1 img {
    width: 200px;
    height: 200px;
    border-radius: 50%;
    padding: 5px;
    background-color: lightgray;
}

.project h2 {
    margin-top: 20px;
    font-size: 20px;
}

.project1 p {
    font-size: 16px;
    font-weight: 500;
    margin-top: 20px;
    max-width: 600px;
}

.project2 {
    border: 2px solid red;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 20px;
}

@media (max-width:500px) {
    .project {
        align-items: center;
        padding-left: -6px;
        flex-direction: column;
    }
    .projPara {
        margin-top: 58px;
    }
    .project1s {
        width: 100%;
        margin: 4px;
        align-items: center;
        flex-direction: column;
        padding: 10px;
    }
    .project1 img {
        width: 130px;
        height: 130px;
    }
}