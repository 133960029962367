.Header {
    position: fixed;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: white;
    padding: 14px;
    z-index: 50;
    background: linear-gradient(to right, rgb(91, 0, 176), rgb(154, 2, 162), rgb(185, 3, 112), rgb(254, 8, 59));
}

.Header1 {
    background: black;
}

.left {
    font-style: italic;
    font-size: 30px;
    font-weight: bolder;
    letter-spacing: 1.1px;
}

.left span {
    font-weight: 2000;
    color: red;
}

.right {
    display: flex;
    font-style: italic;
    align-items: center;
    margin-left: 20px;
    justify-content: space-between;
}

.right p {
    font-size: 20px;
    font-weight: 700;
    margin-left: 25px;
    transition: all .3 ease-in-out;
    cursor: pointer;
}

.link {
    font-size: 20px;
    font-weight: 700;
    margin-left: 25px;
    transition: all .3 ease-in-out;
    cursor: pointer;
    padding: 5px;
    text-align: center;
}

.link1 {
    font-size: 20px;
    font-weight: 700;
    margin-left: 25px;
    transition: all .3 ease-in-out;
    cursor: pointer;
    padding: 5px;
    text-align: center;
    color: white;
}

.link1:hover {
    transform: scaleX(1.1);
}

.link:hover {
    font-weight: 1000;
    color: rgba(255, 0, 0, .9);
}

@media (max-width: 600px) {
    .right {
        display: none;
    }
}